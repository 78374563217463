import React from 'react';
import styled from 'styled-components';
import { Typography, Box, Grid, Image, Button } from '.';
import bannerBg from '../images/services/bg.png';
import { Link } from 'gatsby';

const ServicesHeroSection = styled.section`
  height: 320px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 90%;
  background-image: url(${bannerBg});
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    background-size: 100%;
    height: auto;
  }
`;

const ResponsiveTypography = styled(Typography)`
  padding: 25px 0;
  text-align: center;
  @media (max-width: 768px) {
    padding: 5px 0;
    font-size: 20px;
    line-height: 30px;
  }
`;

const ButtonBox = styled(Box)`
  text-align: center;
`;

const RootBox = styled(Box)`
  padding: 20px;
  text-align: center;
`;

const ServiceHeroSection = ({ title, imageSrc, imgAlt }) => (
  <>
    <ServicesHeroSection>
      <Grid
        gridTemplateColumns={{ _: '1fr', sm: '5fr 3fr' }}
        alignItems="center"
      >
        <RootBox>
          <ResponsiveTypography variant="heading1">
            {title}
          </ResponsiveTypography>
          <ButtonBox>
            <Link to="/contact/">
              <Button variant="contained" color="primary">
                Get a Quote
              </Button>
            </Link>
          </ButtonBox>
        </RootBox>
        <Box display={{ _: 'none', sm: 'block' }}>
          <Image
            style={{ float: 'right', margin: '50px', marginRight: '0px' }}
            src={imageSrc}
            alt={imgAlt}
          />
        </Box>
      </Grid>
    </ServicesHeroSection>
  </>
);

export default ServiceHeroSection;

import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Typography,
  Box,
  Container,
  Section,
  Layout,
  Grid,
  Image,
  Link,
  Button,
} from '../components';
import styled from 'styled-components';
import ServiceHeroSection from '../components/ServiceHeroSection';
import bannerImage from '../images/services/cloudServices/cloudServicesBanner.svg';
import cloudStrategy from '../images/services/cloudServices/cloudStrategy.svg';
import cloudMigration from '../images/services/cloudServices/cloudMigration.svg';
import devOps from '../images/services/cloudServices/devOps.svg';
import security from '../images/services/cloudServices/security.svg';
import Flex from '../components/Flex';
import { AnimatePresence, motion } from 'framer-motion';
import { stats, techLanguages, technologies } from '../config/data';

const ServiceContentImage = styled.img`
  box-shadow: 25px 25px 0px 0px var(--secondary-color);
  margin: 25px 0;
  width: 100%;

  @media screen and (max-width: 768px) {
    box-shadow: 10px 10px 0px 0px var(--secondary-color);
  }
`;

const MotionBox = motion(
  styled.div`
    border: 1px solid #152539;
    cursor: pointer;
    background-color: var(--tech-bg);
    height: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  `
);
const ValueBox = styled(Box)`
  background-color: #061324;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  margin: 10px;
  height: 150px;

  @media (max-width: 768px) {
    padding: 15px;
    margin: 5px;
  }
`;
const techBoxVariants = {
  initial: { opacity: 0, y: 20 },
  hover: { scale: 1.1 },
  enter: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -20 },
};
const GridContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  flex-direction: column;

  @media (min-width: 600px) {
    flex-direction: row;
  }
`;
const cloudService = () => {
  return (
    <Layout title="Cloud Services">
      <Helmet>
        <meta
          name="description"
          content="Access top-tier cloud experts for reliable, managed services. Customized strategies, seamless migrations, DevOps automation, and robust security for your cloud journey."
        />
      </Helmet>
      <Container mt={{ xs: 8, md: 12 }}>
        <ServiceHeroSection
          title={'Cloud Services'}
          imageSrc={bannerImage}
          imgAlt={'Cloud Services'}
        />
        <Section>
          <Typography my={5} variant="paragraph1">
            Get access to top-tier certified experts to navigate the cloud
            journey based on your objectives. We offer highly reliable and
            managed cloud services with transparent roadmaps and service-level
            agreements.
          </Typography>

          <Grid
            gap="150px"
            gapSm="15px"
            alignItems="center"
            gridTemplateColumns={{ _: '1fr', sm: '3fr 3fr' }}
            mb={3}
          >
            <Box>
              <ServiceContentImage src={cloudStrategy} />
            </Box>
            <Box>
              <Typography mt={2} variant="heading5">
                Cloud Strategy
              </Typography>

              <Typography mt={2} variant="paragraph1">
                We conduct a thorough assessment of your IT infrastructure and
                develop a customized cloud roadmap to achieve your objectives.
              </Typography>
            </Box>
          </Grid>

          <Grid
            gap="150px"
            gapSm="15px"
            alignItems="center"
            gridTemplateColumns={{ _: '1fr', sm: '3fr 3fr' }}
            mb={3}
          >
            <Box flip={true}>
              <Typography mt={2} variant="heading5">
                Cloud Migration
              </Typography>

              <Typography mt={2} variant="paragraph1">
                We tailor a migration plan that minimizes disruption, ensures
                data integrity, and optimizes your cloud environment for peak
                performance.
              </Typography>
            </Box>
            <Box>
              <ServiceContentImage
                style={{ float: 'right' }}
                src={cloudMigration}
              />
            </Box>
          </Grid>

          <Grid
            gap="150px"
            gapSm="15px"
            alignItems="center"
            gridTemplateColumns={{ _: '1fr', sm: '3fr 3fr' }}
            mb={3}
          >
            <Box>
              <ServiceContentImage src={devOps} />
            </Box>
            <Box>
              <Typography mt={2} variant="heading5">
                DevOps
              </Typography>

              <Typography mt={2} variant="paragraph1">
                Our DevOps specialists streamline the process by implementing
                automated processes, infrastructure as code (IaC), and
                continuous integration/continuous delivery (CI/CD) pipelines.
              </Typography>
            </Box>
          </Grid>

          <Grid
            gap="150px"
            gapSm="15px"
            alignItems="center"
            gridTemplateColumns={{ _: '1fr', sm: '3fr 3fr' }}
            mb={3}
          >
            <Box flip={true}>
              <Typography mt={2} variant="heading5">
                Security
              </Typography>

              <Typography mt={2} variant="paragraph1">
                We offer advanced access controls, encryption protocols, and
                ongoing security monitoring to ensure the confidentiality,
                integrity, and availability of your data.
              </Typography>
            </Box>
            <Box>
              <ServiceContentImage style={{ float: 'right' }} src={security} />
            </Box>
          </Grid>
        </Section>
        <Section>
          <Grid gridTemplateColumns={{ _: '1fr', sm: '1fr' }}>
            <Box>
              <Typography
                style={{ textAlign: 'center' }}
                variant="heading3"
                mb={3}
              >
                How Experienced We Are
              </Typography>
            </Box>
          </Grid>
          <Grid
            gridTemplateColumns={{
              _: '1fr',
              sm: '1fr 1fr',
              md: '1fr 1fr 1fr 1fr',
            }}
          >
            {stats.map(stat => (
              <ValueBox key={stat.label}>
                <Typography variant="heading3">{stat.value}</Typography>
                <Typography variant="paragraph1">{stat.label}</Typography>
              </ValueBox>
            ))}
          </Grid>
        </Section>
        <Section>
          <Grid
            gridTemplateColumns={{ _: '1fr', sm: '1fr' }}
            justifyItems="center"
          >
            <Box>
              <Typography
                style={{ textAlign: 'center' }}
                variant="heading3"
                mb={3}
              >
                Technologies We Have Expertise In
              </Typography>
            </Box>
          </Grid>
          <Flex>
            <AnimatePresence>
              {technologies
                .filter(tech => tech.category === 'Cloud')
                .map((tech, index) => (
                  <div key={index}>
                    <MotionBox
                      initial="initial"
                      animate="enter"
                      exit="exit"
                      whileHover="hover"
                      variants={techBoxVariants}
                      transition={{ duration: 0.5 }}
                    >
                      <Image width="100px" src={tech.src} alt={tech.alt} />
                    </MotionBox>
                  </div>
                ))}
            </AnimatePresence>
          </Flex>
        </Section>
        <Section>
          <Grid gridTemplateColumns={{ _: '1fr', sm: '1fr' }}>
            <Box>
              <Typography
                style={{ textAlign: 'center' }}
                variant="heading3"
                mb={3}
              >
                Ready To Build Your Brand?
              </Typography>
            </Box>
          </Grid>
          <GridContainer>
            <ButtonContainer>
              <Link to="/contact/">
                <Button variant="contained" color="primary" primary>
                  Contact Us
                </Button>
              </Link>
              <Link to="/expertise/">
                <Button variant="contained" color="primary">
                  View All Services
                </Button>
              </Link>
            </ButtonContainer>
          </GridContainer>
        </Section>
      </Container>
    </Layout>
  );
};

export default cloudService;
